import 'react-native-reanimated';
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { AuthProvider, useAuth } from './AuthContext';
import { Auth0Provider, useAuth0 } from './Auth0Context';
import { colors, fonts, spacing } from './theme';
import { Logger } from './logger';
import BalanceScreen from './screens/BalanceScreen';
import AccountScreen from './screens/AccountScreen';
import InvoiceScreen from './screens/InvoiceScreen';
import ProfileScreen from './screens/ProfileScreen';
import LoginScreen from './screens/LoginScreen';
import LoadingScreen from './screens/LoadingScreen';
import RegisterScreen from './screens/RegisterScreen';
import { AlertProvider } from './components/AlertProvider';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const ProfileIcon = ({ navigation }) => (
  <TouchableOpacity onPress={() => navigation.navigate('Profile')}>
    <Ionicons name="person-circle-outline" size={24} color="#007AFF" style={{ marginRight: 15 }} />
  </TouchableOpacity>
);

function DrawerNavigator() {
  return (
    <Drawer.Navigator>
      <Drawer.Screen name="Balance" component={BalanceScreen} />
      <Drawer.Screen name="Account" component={AccountScreen} />
      <Drawer.Screen name="Invoice" component={InvoiceScreen} />
    </Drawer.Navigator>
  );
}

function AppNavigator() {
  const { user, loading, logout } = useAuth();
  const { Auth0User,auth0Loading, auth0Logout } = useAuth0();
  
  Logger.dev('Current user state:', user);
  Logger.dev('Loading state:', loading);
  Logger.dev('Auth0 Loading state:', auth0Loading);

  if (loading || auth0Loading) {
    return <LoadingScreen />;
  }

  const handleLogout = async () => {
    await Promise.all([logout(), auth0Logout()]);
  };

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor: colors.background,
          },
          headerTintColor: colors.primary,
          headerTitleStyle: {
            fontWeight: 'bold',
            fontSize: fonts.large,
          },
        }}
      >
        {user ? (
          <>
            <Stack.Screen
              name="Main"
              component={DrawerNavigator}
              options={({ navigation }) => ({
                headerRight: () => (
                  <View style={styles.headerRightContainer}>
                    <TouchableOpacity
                      onPress={handleLogout}
                      style={styles.logoutButton}
                    >
                      <Text style={styles.logoutButtonText}>Logout</Text>
                    </TouchableOpacity>
                    <ProfileIcon navigation={navigation} />
                  </View>
                ),
              })}
            />
            <Stack.Screen name="Profile" component={ProfileScreen} />
          </>
        ) : (
          <>
            <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
            <Stack.Screen name="Register" component={RegisterScreen} options={{ headerShown: false }}/>
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerRightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: spacing.medium,
  },
  logoutButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: spacing.medium,
    paddingVertical: spacing.small,
    borderRadius: 5,
    marginRight: spacing.medium,
  },
  logoutButtonText: {
    color: colors.white,
    fontSize: fonts.regular,
    fontWeight: 'bold',
  },
});

export default function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <Auth0Provider>
          <AppNavigator />
        </Auth0Provider>
      </AuthProvider>
    </AlertProvider>
  );
}