import { Alert, Platform } from 'react-native';

export const showAlert = (title, message, buttons = [{ text: 'OK' }]) => {
  if (Platform.OS === 'web') {
    // For web, create a custom alert dialog
    const alertEvent = new CustomEvent('showAlert', {
      detail: { title, message, buttons },
    });
    window.dispatchEvent(alertEvent);
  } else {
    // For mobile, use React Native's Alert
    Alert.alert(title, message, buttons);
  }
}; 