import React from 'react';
import { View, Platform } from 'react-native';

export const Form = ({ children, onSubmit, style }) => {
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    onSubmit();
  };

  return Platform.OS === 'web' ? (
    <form onSubmit={handleSubmit} style={style}>
      {children}
    </form>
  ) : (
    <View style={style}>
      {children}
    </View>
  );
}; 