import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Text, Pressable, Image, Dimensions, ScrollView } from 'react-native';
import { useAuth } from '../AuthContext';
import { colors, fonts, spacing, commonStyles, logoStyles } from '../theme';
import { Logger } from './../logger';
import { showAlert } from '../utils/alert';
import { validateEmail, validatePassword } from '../utils/validation';
import { Form } from '../components/Form';

const RegisterScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [isInvalid, setIsInvalid] = useState(true);
  const { register } = useAuth();

  const checkFormValidity = (fName, lName, emailVal, passwordVal) => {
    const emailCheck = validateEmail(emailVal);
    const passwordCheck = validatePassword(passwordVal);
    return !emailCheck.isValid || !passwordCheck.isValid || !fName || !lName;
  };

  const handleFirstNameChange = (text) => {
    setFirstName(text);
    setFirstNameError(text ? '' : 'First name is required');
    setIsInvalid(checkFormValidity(text, lastName, email, password));
  };

  const handleLastNameChange = (text) => {
    setLastName(text);
    setLastNameError(text ? '' : 'Last name is required');
    setIsInvalid(checkFormValidity(firstName, text, email, password));
  };

  const handleEmailChange = (text) => {
    setEmail(text);
    const emailValidation = validateEmail(text);
    setEmailError(emailValidation.message);
    setIsInvalid(checkFormValidity(firstName, lastName, text, password));
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
    const passwordValidation = validatePassword(text);
    setPasswordError(passwordValidation.message);
    setIsInvalid(checkFormValidity(firstName, lastName, email, text));
  };

  const handleRegister = async () => {
    if (isInvalid) {
      return;
    }

    try {
      await register(firstName, lastName, email, password);
      showAlert(
        'Success', 
        'Registration successful!', 
        [{ 
          text: 'OK', 
          onPress: () => navigation.navigate('Login') 
        }]
      );
    } catch (error) {
      Logger.error('Registration error:', error);
      showAlert(
        'Registration Failed', 
        error.message || 'An unexpected error occurred. Please try again.',
        [{ text: 'OK' }]
      );
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Image
            source={require('../assets/Initech_logo.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <Form 
          style={styles.formContainer}
          onSubmit={handleRegister}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Register</Text>
          </View>
          <View style={styles.inputContainer}>
            <TextInput
              style={[styles.input, firstNameError && styles.inputError]}
              placeholder="First Name"
              value={firstName}
              onChangeText={handleFirstNameChange}
              autoComplete="given-name"
              autoCapitalize="words"
              type="text"
            />
            {firstNameError ? (
              <Text style={styles.errorText}>{firstNameError}</Text>
            ) : null}
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[styles.input, lastNameError && styles.inputError]}
              placeholder="Last Name"
              value={lastName}
              onChangeText={handleLastNameChange}
              autoComplete="family-name"
              autoCapitalize="words"
              type="text"
            />
            {lastNameError ? (
              <Text style={styles.errorText}>{lastNameError}</Text>
            ) : null}
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[styles.input, emailError && styles.inputError]}
              placeholder="Email"
              value={email}
              onChangeText={handleEmailChange}
              autoComplete="email"
              autoCapitalize="none"
              type="email"
            />
            {emailError ? (
              <Text style={styles.errorText}>{emailError}</Text>
            ) : null}
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[styles.input, passwordError && styles.inputError]}
              placeholder="Password"
              value={password}
              onChangeText={handlePasswordChange}
              secureTextEntry
              autoComplete="new-password"
              type="password"
            />
            {passwordError ? (
              <Text style={styles.errorText}>{passwordError}</Text>
            ) : null}
          </View>

          <Pressable 
            style={[styles.button, isInvalid && styles.buttonDisabled]}
            onPress={handleRegister}
            disabled={isInvalid}
            role="button"
          >
            <Text style={[styles.buttonText, isInvalid && styles.buttonTextDisabled]}>
              Register
            </Text>
          </Pressable>
        </Form>
        <View style={styles.footer}>
          <Text style={styles.footerText}>Already have an account? </Text>
          <Pressable onPress={() => navigation.navigate('Login')}>
            <Text style={styles.signUpText}>Login</Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  ...commonStyles,
  ...logoStyles,
  scrollContainer: {
    flexGrow: 1,
    minHeight: '100%',
  },
  container: {
    ...commonStyles.container,
    marginBottom: 0,
    paddingVertical: spacing.large,
  },
  inputContainer: {
    width: '100%',
    marginBottom: spacing.medium,
  },
  input: {
    ...commonStyles.input,
    marginBottom: spacing.small,
  },
  inputError: {
    borderColor: colors.error,
    borderWidth: 1,
  },
  errorText: {
    color: colors.error,
    fontSize: fonts.regular,
    marginLeft: spacing.small,
  },
});

export default RegisterScreen;