import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Text, Pressable, Image, Dimensions, ScrollView } from 'react-native';
import { useAuth } from '../AuthContext';
import { useAuth0 } from '../Auth0Context';
import { colors, fonts, spacing, commonStyles, logoStyles } from '../theme';
import { Logger } from '../logger';
import { showAlert } from '../utils/alert';
import { validateEmail, validatePassword } from '../utils/validation';
import { Form } from '../components/Form';

const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isInvalid, setIsInvalid] = useState(true);
  const { login } = useAuth();
  const { auth0Login } = useAuth0();

  const checkFormValidity = (emailVal, passwordVal) => {
    const emailCheck = validateEmail(emailVal);
    const passwordCheck = validatePassword(passwordVal);
    return !emailCheck.isValid || !passwordCheck.isValid;
  };

  const handleEmailChange = (text) => {
    setEmail(text);
    const emailValidation = validateEmail(text);
    setEmailError(emailValidation.message);
    setIsInvalid(checkFormValidity(text, password));
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
    const passwordValidation = validatePassword(text);
    setPasswordError(passwordValidation.message);
    setIsInvalid(checkFormValidity(email, text));
  };

  const handleLogin = async () => {
    if (isInvalid) {
      return;
    }

    try {
      await login(email, password);
    } catch (error) {
      Logger.error('Login error:', error);
      showAlert(
        'Login Failed', 
        error.message || 'Invalid email or password. Please try again.'
      );
    }
  };

  const handleAuth0Login = async () => {
    try {
      await auth0Login();
    } catch (error) {
      Logger.error('Auth0 login error:', error);
      showAlert(
        'Login Failed', 
        error.message || 'Unable to login with Auth0. Please try again.'
      );
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Image
            source={require('../assets/Initech_logo.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <Form 
          style={styles.formContainer} 
          onSubmit={handleLogin}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Sign In</Text>
          </View>
          <View style={styles.inputContainer}>
            <TextInput
              style={[styles.input, emailError && styles.inputError]}
              placeholder="Username or email address"
              value={email}
              onChangeText={handleEmailChange}
              inputMode="email"
              autoCapitalize="none"
              autoComplete="email"
              type="email"
            />
            {emailError ? (
              <Text style={styles.errorText}>{emailError}</Text>
            ) : null}
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[styles.input, passwordError && styles.inputError]}
              placeholder="Password"
              value={password}
              onChangeText={handlePasswordChange}
              secureTextEntry
              autoComplete="current-password"
              type="password"
            />
            {passwordError ? (
              <Text style={styles.errorText}>{passwordError}</Text>
            ) : null}
          </View>

          <Pressable 
            style={[styles.button, isInvalid && styles.buttonDisabled]} 
            onPress={handleLogin}
            disabled={isInvalid}
            role="button"
          >
            <Text style={[styles.buttonText, isInvalid && styles.buttonTextDisabled]}>
              Sign In
            </Text>
          </Pressable>
          <View style={styles.dividerContainer}>
            <View style={styles.divider} />
            <Text style={styles.dividerText}>or</Text>
            <View style={styles.divider} />
          </View>
          <Pressable 
            style={({ pressed }) => [
              styles.button,
              styles.auth0Button,
              pressed && styles.auth0ButtonPressed
            ]}
            onPress={handleAuth0Login}
          >
            <Text style={styles.buttonText}>
              Continue with Auth0
            </Text>
          </Pressable>
          <Pressable onPress={() => {}}>
            <Text style={styles.forgotPassword}>Forgot password?</Text>
          </Pressable>
        </Form>
        <View style={styles.footer}>
          <Text style={styles.footerText}>New to our demo platform? </Text>
          <Pressable onPress={() => navigation.navigate('Register')}>
            <Text style={styles.signUpText}>Sign Up</Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  ...commonStyles,
  ...logoStyles,
  scrollContainer: {
    flexGrow: 1,
    minHeight: '100%',
  },
  container: {
    ...commonStyles.container,
    marginBottom: 0,
    paddingVertical: spacing.large,
  },
  auth0Button: {
    backgroundColor: '#635DFF',
    marginTop: spacing.medium,
  },
  auth0ButtonPressed: {
    opacity: 0.8,
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: spacing.medium,
  },
  divider: {
    flex: 1,
    height: 1,
    backgroundColor: colors.border,
  },
  dividerText: {
    marginHorizontal: spacing.medium,
    fontSize: fonts.body,
    color: colors.text,
  },
  inputContainer: {
    width: '100%',
    marginBottom: spacing.medium,
  },
  input: {
    ...commonStyles.input,
    marginBottom: spacing.small,
  },
  inputError: {
    borderColor: colors.error,
    borderWidth: 1,
  },
  errorText: {
    color: colors.error,
    fontSize: fonts.regular,
    marginLeft: spacing.small,
  },
});

export default LoginScreen;
