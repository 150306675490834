// logger.js

const LOG_LEVELS = {
    DEV: 'DEV',
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR'
};

const LOG_LEVEL_VALUES = {
    DEV: 0,
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4
};

const CURRENT_LOG_LEVEL = __DEV__ ? LOG_LEVEL_VALUES.DEV : LOG_LEVEL_VALUES.WARN;

const log = (level, message, ...args) => {
    if (level >= CURRENT_LOG_LEVEL) {
        const timestamp = new Date().toISOString();
        const levelName = Object.keys(LOG_LEVEL_VALUES).find(key => LOG_LEVEL_VALUES[key] === level);
        const prefix = `[${timestamp}] ${levelName}:`;
        
        switch (level) {
            case LOG_LEVEL_VALUES.DEV:
                console.log(prefix, message, ...args);
                break;
            case LOG_LEVEL_VALUES.DEBUG:
                console.log(prefix, message, ...args);
                break;
            case LOG_LEVEL_VALUES.INFO:
                console.info(prefix, message, ...args);
                break;
            case LOG_LEVEL_VALUES.WARN:
                console.warn(prefix, message, ...args);
                break;
            case LOG_LEVEL_VALUES.ERROR:
                console.error(prefix, message, ...args);
                break;
        }
    }
};

export const Logger = {
    dev: (message, ...args) => log(LOG_LEVEL_VALUES.DEV, message, ...args),
    debug: (message, ...args) => log(LOG_LEVEL_VALUES.DEBUG, message, ...args),
    info: (message, ...args) => log(LOG_LEVEL_VALUES.INFO, message, ...args),
    warn: (message, ...args) => log(LOG_LEVEL_VALUES.WARN, message, ...args),
    error: (message, ...args) => log(LOG_LEVEL_VALUES.ERROR, message, ...args),
};