import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, Text, Pressable, Dimensions } from 'react-native';
import { colors, fonts, spacing, commonStyles } from '../theme';

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleAlert = (event) => {
        setAlert(event.detail);
      };

      window.addEventListener('showAlert', handleAlert);
      return () => window.removeEventListener('showAlert', handleAlert);
    }
  }, []);

  if (!alert || Platform.OS !== 'web') {
    return children;
  }

  const isError = alert.title.includes('Failed') || alert.title.includes('Error');

  return (
    <>
      {children}
      {alert && (
        <View style={styles.overlay}>
          <View style={[
            styles.alertBox,
            isError && styles.errorAlertBox
          ]}>
            <View style={[
              styles.titleContainer,
              isError && styles.errorTitleContainer
            ]}>
              <Text style={[
                styles.title,
                isError && styles.errorTitle
              ]}>
                {alert.title}
              </Text>
            </View>
            <View style={styles.messageContainer}>
              <Text style={[
                styles.message,
                isError && styles.errorMessage
              ]}>
                {alert.message}
              </Text>
            </View>
            <View style={[
              styles.buttonContainer,
              isError && styles.errorButtonContainer
            ]}>
              {alert.buttons.map((button, index) => (
                <Pressable
                  key={index}
                  style={({ pressed }) => [
                    styles.button,
                    index === alert.buttons.length - 1 && styles.primaryButton,
                    pressed && styles.buttonPressed,
                    isError && styles.errorButton
                  ]}
                  onPress={() => {
                    if (button.onPress) {
                      button.onPress();
                    }
                    setAlert(null);
                  }}
                >
                  <Text style={[
                    styles.buttonText,
                    isError && styles.errorButtonText
                  ]}>
                    {button.text}
                  </Text>
                </Pressable>
              ))}
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backdropFilter: 'blur(5px)',
  },
  alertBox: {
    ...commonStyles.formContainer,
    width: Dimensions.get('window').width * 0.6,
    maxWidth: 300,
    minWidth: 220,
    maxHeight: '90%',
    padding: spacing.large,
  },
  errorAlertBox: {
    backgroundColor: colors.white,
    borderRadius: 8,
    shadowColor: colors.shadow,
    shadowoffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,

  },
  titleContainer: {
    width: '100%',
    marginBottom: spacing.medium,
  },
  errorTitleContainer: {
    borderBottomColor: colors.error,
  },
  title: {
    ...commonStyles.title,
    marginBottom: 0,
  },
  errorTitle: {
    color: colors.text,
  },
  messageContainer: {
    width: '100%',
    marginBottom: spacing.large,
  },
  message: {
    fontSize: fonts.medium,
    color: colors.text,
    textAlign: 'center',
  },
  errorMessage: {
    color: '#991B1B',
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: spacing.medium,
  },
  button: {
    ...commonStyles.button,
    minWidth: 100,
    width: 'auto',
  },
  primaryButton: {
    backgroundColor: colors.button,
  },
  errorButton: {
    backgroundColor: colors.error,
  },
  buttonPressed: {
    opacity: 0.8,
  },
  buttonText: {
    ...commonStyles.buttonText,
  },
  errorButtonText: {
    color: colors.white,
  },
}); 